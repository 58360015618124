/* eslint-disable @wordpress/dependency-group */
import closeModal from './close-modal';

const modalControl = document.querySelector('[rel="modal-control"]');

if (modalControl) {
	const modalWindow = document.querySelector('[rel="site-modal"]'),
		html = document.querySelector('html'),
		body = document.getElementsByTagName('body')[0];
	modalControl.addEventListener('click', () => {
		closeModal();
		html.style.overflowX = 'initial';
		body.classList.add('compensate-for-scrollbar');
		modalWindow.classList.add('fade-in');
	});
}
